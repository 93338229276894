import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.scss';

const Button = ({ handlePayment, title, header, disabled }) => {
  return (
    <button
      className={styles.paymentCard}
      onClick={handlePayment}
      disabled={disabled}
    >
      <div className={styles.paymentCardHeader}>{header}</div>
      <div className={styles.paymentCardInfo}>
        <div className={styles.paymentCardMethod}>
          <p className={styles.paymentCardName}>{title}</p>
        </div>
      </div>
    </button>
  );
};

Button.propTypes = {
  handlePayment: PropTypes.func,
  header: PropTypes.element,
  title: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
